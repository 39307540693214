.wp-block-button .wp-block-button__link.is-style-outline,
.wp-block-button.is-style-outline > .wp-block-button__link {
  border-width: 1px !important;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  letter-spacing: 0.3px;
  padding: calc(0.8em + 2px) calc(1.5em + 2px) !important;
}

.wp-block-button.is-style-outline
  > .wp-block-button__link:not(.has-background):hover {
  background-color: $color-hover;
  border-color: $color-hover;
}
