.js {
  &-heading {
    overflow: hidden;
    max-width: 35ch;
    width: 100%;
    font-size: max(4vw, 3.5rem) !important;
    line-height: 1.2 !important;

    &:not(.splitting) {
      opacity: 0;
    }
  }

  &-pin-wrap {
    position: relative;
  }

  &-pin-target {
    position: relative;
    z-index: 2;
  }
}
