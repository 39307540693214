.c-tease {
  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  &__title {
    padding: 1em 0;
    margin-bottom: 0;

    h2 {
      font-family: $font-sans-serif;
      font-size: 1rem;
    }
  }

  &__media {
    transition: $global-transition;
    width: 100%;
    height: auto;

    @include bp(medium) {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  &__link {
    border-bottom: none !important;
  }

  &__linkwrap {
    line-height: 0;
  }

  &__link-text {
    display: block;
    line-height: 1.25;
  }

  &__link-meta {
    font-family: $font-sans-serif;
    transition: $global-transition;
    line-height: 1;
    font-size: 0.85rem;
    opacity: 0;
    transform: translateY(0.5em);
    display: inline-block;
    margin: 0 0.15em;
  }

  &:hover .c-tease__link-meta {
    opacity: 1;
    transform: translateY(0);
  }
}

.c-tease--post {
  .c-tease__link {
    &:hover > * {
      opacity: 0.5;
    }
  }

  .c-tease__title {
    font-family: $font-sans-serif !important;
    font-size: 1rem !important;
    font-weight: normal !important;
  }
}

.c-tease--talent,
.c-tease--clips {
  .c-tease__wrapper {
    aspect-ratio: 1.5/1;
    background-color: rgba($color-fresh, 0.1);
    position: relative;
  }

  .c-tease__title {
    transition: $global-transition;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    top: 0;
  }

  .c-tease__media {
    height: 120%;
    object-fit: cover;
    transition: all linear;
  }

  .c-tease__link {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    text-align: center;
  }

  .c-tease__link:hover {
    background-color: rgba(25, 201, 200, 0.6);
    backdrop-filter: blur(4px) brightness(0.75) grayscale(1);
  }
}

.c-tease__media.lazyload {
  opacity: 0;
}

.c-tease__media.lazyloaded {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
