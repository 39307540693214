.c-newsletter {
  width: calc(100% - 2em);
  max-width: 380px;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  letter-spacing: 0.3px;

  form {
    display: flex;
  }

  input[type='email'] {
    width: 100%;
  }

  input[type='submit'] {
    cursor: pointer;
    font-weight: 900;
    transition: $global-transition;
    text-transform: uppercase;

    &:hover {
      color: $color-fresh;
    }
  }

  input {
    font-family: $font-sans-serif;
    font-size: 1em;
    color: $color-white;
    appearance: none;
    outline: none;
    border: none;
    border-bottom: 1px solid $color-fresh;
    background: transparent;
    line-height: 1em;
    padding: 0.35em 0;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  /* stylelint-disable selector-class-pattern */

  .gform_validation_errors {
    display: none;
  }

  .gform_body {
    flex: 1;
  }

  .gform_footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .gfield_required {
    display: none;
  }

  .gfield_label {
    margin-bottom: 0.6em;
    display: block;
  }

  /* stylelint-enable selector-class-pattern */
}
