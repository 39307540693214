.c-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transform: translateY(-100%);

  &__content {
    @extend %o-wrapper;

    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    transform: translateY(20px);
    opacity: 0;
  }

  &__video {
    width: 100%;
    height: 100%;
  }

  &__player {
    width: 100%;
    height: 100%;
  }

  &__info {
    @extend %o-wrapper;

    width: 100%;
    transform: translateY(20px);
    opacity: 0;
  }

  &__title {
    margin: 0;
  }

  &__description {
    margin-top: 1.3rem;

    @include bp(small) {
      display: flex;
      align-items: baseline;
      gap: 0.5em;
    }
  }

  &__meta {
    margin-bottom: 1.3rem;
  }

  &__nav {
    display: flex;
    position: relative;

    @include bp-down(small) {
      margin-bottom: 1.3rem;
    }

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 6em;
      background: #19c9c8;
      right: calc(100% + 2em);
      position: absolute;
      top: 50%;

      @include bp-down(small) {
        position: static;
        margin-right: 1em;
      }
    }
  }

  &__nav-item {
    cursor: pointer;
    margin: 0 1em 0 0;
  }
}

.c-lightbox.is-active {
  transform: translateY(0);
  animation: bgFadeUp 0.3s ease-in-out forwards;

  .c-lightbox__content {
    animation: animInUp 0.4s 0.3s ease-in-out forwards;
  }

  .c-lightbox__info {
    animation: animInUp 0.4s 0.5s ease-in-out forwards;
  }
}

@keyframes bgFadeUp {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }

  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}

@keyframes animInUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
