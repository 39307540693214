/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer__misc {
  li {
    width: calc(100% - 1em);
  }
}

.c-footer {
  margin-top: 8em;
  margin-bottom: 2em;

  li {
    margin-bottom: 0.75em;
  }

  &__subtitle {
    font-weight: 900;
  }

  &__newsletter,
  &__nav,
  &__misc {
    flex: 1;
  }

  &__links {
    flex: 2;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 100%;
      opacity: 0.75;

      @include bp(large) {
        width: calc(50% - 1em);
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__quiet {
    a {
      margin-right: 1em;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.c-footer__social {
  .c-nav__list .c-nav__item {
    display: inline-block;
    margin-right: 1em;
  }
}
