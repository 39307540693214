.c-hero {
  overflow: hidden;
  min-height: 100vh;

  &__clip {
    video {
      height: 100vh;
      object-fit: cover;
    }
  }
}
