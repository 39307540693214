/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */

.o-layout {
  display: block;

  @include bp(small) {
    display: flex;
    flex-wrap: wrap;
  }
}

.o-layout__masonry-gutter {
  width: 2%;
}

.o-layout__item {
  flex: 1;
  margin-bottom: 4%;

  @include bp(small) {
    flex: none;
    width: 49%;
    margin-bottom: 2%;
  }
}

.o-layout--guttered {
  gap: 2%;

  > .o-layout__item {
    flex: none;
    display: block;
  }

  &.o-layout--sb {
    justify-content: space-between;
  }
}

.o-layout--column {
  @include bp(medium) {
    flex-direction: column;
  }
}

.o-layout--3 {
  > .o-layout__item {
    @include bp(large) {
      width: 32%;
      flex: none;
    }
  }
}

.o-layout--4 {
  > .o-layout__item {
    @include bp(medium) {
      width: 24%;
      flex: none;
    }
  }
}

.o-layout--2 {
  @include bp(small) {
    gap: 4%;

    .o-layout__item {
      width: 48%;
      margin-bottom: 4%;
      margin-top: 0;
    }
  }
}

.o-layout--sidebar {
  justify-content: center;
  gap: 2em;

  .o-layout__aside {
    margin-bottom: 2em;
    max-width: 670px;

    @include bp(large) {
      max-width: 480px;
      min-width: 230px;
      margin-top: 0.5em;
      width: calc((100vw - 670px) / 2);
    }
  }
}
