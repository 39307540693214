.u-margin {
  &-top {
    &--huge {
      margin-top: 5rem;
    }
  }

  &-none {
    margin: 0;
  }
}
