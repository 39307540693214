/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-nav {
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  list-style: none;
  margin-left: 0;

  &__list {
    margin: 0;
  }

  &__item {
    display: block;

    @include bp(large) {
      margin: 0 1em 0 0;
      display: inline-block;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__link {
    border-bottom: none !important;
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 600;
    letter-spacing: 0.3px;

    .current-menu-item & {
      color: $color-hover;
    }
  }
}

.c-nav--main {
  @include bp-down(1180px) {
    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='arcs'%3E%3Cline x1='3' y1='12' x2='21' y2='12'%3E%3C/line%3E%3Cline x1='3' y1='6' x2='21' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='18' x2='21' y2='18'%3E%3C/line%3E%3C/svg%3E");
      display: block;
      height: 24px;
      width: 24px;
      cursor: pointer;
    }

    .c-nav__list {
      display: none;
    }

    &.is-active {
      &::before {
        position: absolute;
        top: 2.15em;
        right: 1.5em;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='arcs'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
      }

      animation: openMenu 0.3s linear forwards;
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;

      .c-nav__list {
        flex: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  @include bp-down(medium) {
    &::before {
      top: 1.1em;
    }
  }

  @include bp-down(1180px) {
    .c-nav__item {
      margin: 0 0 1.5em 0;
    }

    .c-nav__link {
      font-size: 1em;
      font-weight: 500;
    }
  }
}

.c-nav--location {
  .c-nav__list {
    display: flex;
  }

  .c-nav__item {
    margin: 0 1em 0 0;
  }

  .is-active .c-nav__link {
    color: $color-fresh;
  }
}

.c-subnav {
  gap: 2em;

  p {
    position: relative;

    &:last-of-type::after {
      content: '';
      display: block;
      height: 1px;
      width: 6em;
      position: absolute;
      background: #19c9c8;
      left: calc(100% + 2em);
      top: 50%;
    }
  }
}

@keyframes openMenu {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: $color-black;
  }
}
