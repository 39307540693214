.u-link {
  &--styled {
    &::after {
      content: '';
      height: 1px;
      width: 4em;
      background-color: $color-fresh;
      display: inline-block;
      margin-left: 0.5em;
      vertical-align: middle;
    }
  }

  &--external {
    &::before {
      content: url('../assets/images/external-link.svg');
      display: inline-block;
      margin-right: 0.5em;
    }
  }

  &--close {
    height: 1.5em;
    width: 1.5em;
    line-height: 0;
    display: inline-block;
    position: absolute;
    border: none !important;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1' stroke-linecap='butt' stroke-linejoin='arcs'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  }
}
