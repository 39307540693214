/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: none;
  transition: $global-transition;
  border-bottom: 1px solid rgba($color-link, 0.5);

  &:hover {
    color: $color-hover;
    border-bottom: 1px solid rgba($color-hover, 0.66);
  }

  &[href*='goo.gl'] {
    height: 1.5em;
    width: 1.5em;
    line-height: 0;
    display: inline-block;
    position: absolute;
    border: none !important;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2319c9c8' stroke-width='1' stroke-linecap='butt' stroke-linejoin='arcs'%3E%3Ccircle cx='12' cy='10' r='3'/%3E%3Cpath d='M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z'/%3E%3C/svg%3E");
  }
}
