.c-indicator {
  background-image: url('../assets/images/scroll-indicator.svg');
  height: 2.25em;
  width: 2.25em;
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  z-index: 9999;
  background-size: cover;
  animation: scroll 1.5s linear infinite;
  border-radius: 50%;
  border: 1px solid white;
  transition: $global-transition;
  user-select: none;
  cursor: none;

  &.is-hidden {
    opacity: 0 !important;
  }

  &:hover {
    animation-duration: 0.75s;
    background-color: $color-black;
  }
}

@keyframes scroll {
  from {
    background-position-y: 0;
  }

  to {
    background-position-y: calc(2.25em - 2px);
  }
}
