/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

%o-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;

  @include bp(medium) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include bp(large) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.o-wrapper {
  @extend %o-wrapper;

  &--narrow {
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }

  &--standard {
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;
  }

  &--flush {
    padding-left: 0;
    padding-right: 0;
  }
}
