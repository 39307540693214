@import 'video.js/dist/video-js';

/* ==========================================================================
   #CUSTOM STYLING VIDEO JS PLAYER
========================================================================== */

.vjs-fresh {
  color: $color-fresh;
  font-size: 0.75rem;

  .vjs-big-play-button {
    background-color: transparent !important;
    border: none;

    .vjs-icon-placeholder {
      display: none;
    }

    .vjs-control-text {
      clip: auto;
      height: 100%;
      width: 100%;
      font-family: $font-serif;
      position: relative;
      display: block;
    }
  }

  .vjs-control-bar {
    background-color: transparent;
  }

  .vjs-volume-panel {
    display: none;
  }

  .vjs-remaining-time {
    display: none;
  }

  .vjs-progress-holder {
    height: 1px;
  }

  .vjs-play-progress {
    background-color: $color-fresh;

    &::before {
      transform: translateY(-50%);
    }
  }

  .vjs-tech {
    transition: opacity 0.2s ease-in-out;
  }

  .vjs-poster {
    transition: opacity 0.2s ease-in-out;
  }

  &:hover:not(.vjs-has-started) {
    .vjs-tech,
    .vjs-poster {
      opacity: 0.35;
    }
  }
}
