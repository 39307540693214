.u-text {
  &-huge {
    font-size: 4.44em !important;
    line-height: 1.1;
  }

  &-large {
    font-size: 2.5em !important;
    line-height: 1.1;
  }
}
