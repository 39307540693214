/* ==========================================================================
   #MASONRY LAYOUT
   ========================================================================== */

:root {
  --default-gutter: 2%;
}

.c-masonry {
  padding: 0 !important;
  margin: 0 0.5em;

  @include bp(small) {
    margin: 0 2em;
  }

  @include bp(large) {
    margin: 0 2em;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  // &__sizer,
  &__item {
    // opacity: 0;
    float: left;
    width: 100%;
    width: calc(100% - 1em);
    margin: 0.5em;

    @include bp(small) {
      width: calc((100% - 2em) / 2);
    }

    @include bp(large) {
      margin: 1em;
      width: calc((100% - 6em) / 3);
    }
    // position: relative;
    // width: calc((100% - (var(--default-gutter) / 2)) / 2);
    // margin-bottom: calc(var(--default-gutter) * 1.5);

    // @include bp(small) {
    //   width: calc((100% - var(--default-gutter)) / 2);
    // }

    // @include bp(large) {
    //   width: calc((100% - (var(--default-gutter)) * 2) / 3);
    // }

    // @include bp(full) {
    //   width: calc((100% - (var(--default-gutter)) * 4) / 3);
    //   margin-bottom: calc(var(--default-gutter) * 2);
    // }
  }

  // &__gutter {
  //   width: calc(var(--default-gutter) / 2);

  //   @include bp(small) {
  //     width: var(--default-gutter);
  //   }

  //   @include bp(full) {
  //     width: calc(var(--default-gutter) * 2);
  //   }
  // }
}

// .c-masonry.c-masonry--ready {
//   .c-masonry__item {
//     animation-name: floatUp;
//     animation-duration: 0.66s;
//     animation-fill-mode: forwards;
//     animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
//   }
// }

// @keyframes floatUp {
//   from {
//     opacity: 0;
//   }

//   to {
//     opacity: 1;
//   }
// }
