.c-cards {
  align-items: center;
  position: relative;

  @include bp(medium) {
    display: flex;
    overflow-x: hidden;
  }

  &__wrapper {
    transition: $global-transition;
    width: 100%;
    gap: 1em;

    @include bp(small) {
      display: flex;
      flex-wrap: wrap;
    }

    @include bp(medium) {
      flex-wrap: nowrap;
    }
  }

  &__item {
    margin-bottom: 2rem;

    @include bp(small) {
      width: calc((100% - 1em) / 2);
    }

    @include bp(medium) {
      width: calc((100% - 1em) / 4);
      overflow: hidden;
    }
  }

  .wp-block-media-text__media {
    margin-bottom: 0.5rem !important;

    @include bp(small) {
      height: 50vh !important;
    }

    a {
      border: none;
    }
  }

  .wp-block-media-text {
    transition: $global-transition;
    display: block;
  }

  .wp-block-media-text__content {
    padding: 0 !important;
  }

  .wp-block-heading {
    transition: $global-transition;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.3px;
    font-family: $font-sans-serif !important;
    font-size: 0.7rem !important;

    a {
      border-bottom: none !important;
      color: inherit;
    }
  }

  .wp-block-media-text__content > *:not(h2) {
    @include bp(medium) {
      opacity: 0;
    }
  }
}

.c-cards__item:hover {
  color: $color-hover;

  @include bp(medium) {
    width: 50%;
    flex: auto;
  }
}
