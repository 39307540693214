/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  transition: opacity 0.3s ease-in-out;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: sticky;
  z-index: 1000;
  width: 100%;
  top: 0;
  display: flex;
  gap: 2em;

  @include bp(medium) {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }

  @include bp(1180px) {
    justify-content: space-between;
    padding-top: 3rem;
    padding-bottom: 5rem;
  }

  &__link {
    border: none !important;
    flex: 1;

    @include bp(1180px) {
      flex: initial;
    }
  }

  &.is-hidden {
    opacity: 0 !important;
    animation: outOfView 0.3s 0.3s ease-in-out forwards;
  }
}

.c-header .c-header__hide {
  display: none !important;
}

@keyframes outOfView {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}
